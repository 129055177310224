<template>
  <div class="trips">
    <lenon-list-page
        title="Trip Attendance"
        :columns="columns"
        :rows="trips"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"y
        show-profile-photo
        search-placeholder="Search"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="trip"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select v-model="selectedClassId" :options="classes" value-name="id" label-name="name" placeholder="Select Class"/>
              </div>
              <div class="col-md-6">
                <lenon-select v-model="selectedTripTypeId" :options="tripTypes" value-name="id" label-name="label" placeholder="Select Trip Type"/>
              </div>
              <div class="col-md-6">
                <lenon-date-picker v-model="date" :show-label="false" label="Attendance Date"/>
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-select v-model="selectedClassId" :options="classes" value-name="id" label-name="name" placeholder="Select Class" class="mr-1"/>
          <lenon-select v-model="selectedTripTypeId" :options="tripTypes" value-name="id" label-name="label" placeholder="Select Trip Type" class="mr-1"/>
          <lenon-date-picker v-model="date" :show-label="false" label="Attendance Date"/>
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.trip
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template #trip_type="{row}">
        <b-badge variant="light-primary">
          {{ [row.item.trip_type].toString().toUpperCase() }}
        </b-badge>
      </template>
      <template #status="{row}">
        <b-badge :variant="row.item.status==='present'?'light-success':'light-danger'">
          {{ [row.item.status].toString().toUpperCase() }}
        </b-badge>
      </template>
    </lenon-list-page>
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge, BCard } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'
import LenonDatePicker from '@/lenon/components/LenonDatePicker'

export default {
  name: 'CompletedAttendances',
  components: {
    LenonDatePicker,
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    LenonButton,
    BBadge,
    BCollapse,
    BCard
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      tripsModalOpened: false,
      tableLoading: false,
      date: null,
      selectedClassId: null,
      selectedTripTypeId: null,
      tripTypes:[{id:"busin",label:"BUS IN"},{id:"busout",label:"BUS OUT"}],
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'name',
          label: `Student Name`,
        },
        {
          key: 'trip_type',
          label: `Trip Type`,
        },
        {
          key: 'status',
          label: `Attendance Status`,
        },
      ],
    }
  },
  computed: {
    trips() {
      return this.$store.getters['trip/tripAttendances']
    },
    attendanceSettings() {
      return this.$store.getters['trip/attendanceSettings']
    },
    classes() {
      return this.$store.getters['termsClasses/allClasses']
    },
  },
  watch: {
    date(val){
      if(this.date && this.selectedClassId && this.selectedTripTypeId){
        this.fetchAttendances();
      }
      this.updateSettings()
    },
    selectedTripTypeId(val){
      if(this.date && this.selectedClassId && this.selectedTripTypeId){
        this.fetchAttendances();
      }
      this.updateSettings()
    },
    selectedClassId(val){
      if(this.date && this.selectedClassId && this.selectedTripTypeId){
        this.fetchAttendances();
      }
      this.updateSettings()
    },
  },
  methods: {
    updateSettings(){
      this.$store.commit('trip/setAttendanceSettings', {
        date: this.date,
        selectedClassId: this.selectedClassId,
        selectedTripTypeId: this.selectedTripTypeId
      })
    },
    fetchAttendances() {
      this.tableLoading = true
      this.$http.get(`transportation/trip-attendances?date=${this.date}&class_id=${this.selectedClassId}&trip_type=${this.selectedTripTypeId}`)
          .then(res => {
            this.$store.commit('trip/setAttendances', res.data)
          })
          .catch(err => {
            this.showError('Failed to load attendances')
          })
          .finally(() => {
            this.tableLoading = false
          })
    },
  },
  mounted() {
    this.date = this.attendanceSettings.date
    this.selectedClassId = this.attendanceSettings.selectedClassId
    this.selectedTripTypeId = this.attendanceSettings.selectedTripTypeId
  }
}
</script>
